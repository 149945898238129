import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';

const SaveTheDate = lazy(() => import('./routes/SaveTheDate'));
const Invitation = lazy(() => import('./routes/Invitation'));
const PhotoGallery = lazy(() => import('./routes/PhotoGallerySolo'));

function App() {
  return <Router>
     <Suspense fallback={<div />}>
       <Switch>
         <Route path="/std" component={() => <SaveTheDate />}/>
         <Route path="/invitation" component={() => <Invitation/>}/>
         <Route path="/:albumId?/:imageId?" exact component={() => <PhotoGallery />} />
       </Switch>
     </Suspense>
   </Router>;
};

export default App;
